export default theme => ({
  root: {
    display: 'block',
    width: 1,
    margin: 0,
    background: theme.palette.border,
    border: 'none',
    flex: 'none',
  },
});
