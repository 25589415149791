import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const FiltersDelimiter = ({ classes, className }) => <hr className={cn(classes.root, className)} />;

FiltersDelimiter.propTypes = {
  classes: PropTypes.shape().isRequired,
  className: PropTypes.string,
};

FiltersDelimiter.defaultProps = {
  className: '',
};

export default withStyles(styles)(FiltersDelimiter);
